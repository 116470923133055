<template>
  <div class="p-5 container">
    <div class="row">
      <div class="col-12">
        <h3>ถ่ายรูปยืนยันตัวตน</h3>
      </div>
      <div class="col-12">
        <p style="font-size:  3vh;color: #323563;" class="mb-5">
          รูปตัวอย่าง
        </p>
      </div>
      <div class="col-12 col-lg-6">
        <div>
          <div :class="preview == true ? 'w-100' : 'w-100 d-none'">
            <canvas
              style="width:100%"
              ref="canvas"
              id="canvas"
              width="600"
              height="450"
            ></canvas>
          </div>

          <img
            src="media/logos/example.png"
            :class="preview == false ? 'w-100 mb-5' : 'w-100 mb-5 d-none'"
          />
          <h3 class="mb-5">ถ่ายรูปยืนยันตัวตน</h3>
          <li style="font-size:16px">
            เตรียมบัตรประชาชนของท่านให้พร้อม
          </li>
          <li style="font-size:16px">
            ถอดหมวกหรืออุปกรณ์อื่นๆที่บดบังใบหน้า เปิดกล้องของคอมพิวเตอร์
          </li>
          <li style="font-size:16px">
            ถือบัตรประชาชนข้างใบหน้า ให้เห็นใบหน้าและข้อมูลบนบัตรชัดเจน
          </li>
          <li style="font-size:16px">กดปุ่มถ่ายรูปและบันทึกข้อมูล</li>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div>
          <div
            style="position: relative;display: flex;align-items: center;flex-direction: column;"
          >
            <video
              v-if="isCameraOpen == true"
              ref="video"
              id="video"
              style="width: 100%;"
              autoplay
            ></video>
            <button
              type="button"
              style="position: absolute;bottom: 15px;"
              class="btn btn-primary"
              @click="capture"
            >
              <i class="fa fa-camera"></i> ถ่ายรูปเพื่อยืนยันตัวตน ( กดอีกครั้งเพื่อถ่ายใหม่ )
            </button>
          </div>
          <div class="fv-row my-5">
            <label class="form-check form-check-custom form-check-solid">
              <input
                class="form-check-input"
                type="checkbox"
                name="toc"
                value="true"
                v-model="toc"
              />
              <span
                class="form-check-label fw-bold text-gray-700"
                style="font-size:16px"
              >
                ฉันยอมรับ
                <a href="#" class="ms-1 link-primary"
                  >เงื่อนไขนโยบายและความเป็นส่วนตัว</a
                >.
              </span>
            </label>
            <!-- <div v-if="!modelRegister.toc" class="text-danger">
            กรุณาระบุ
          </div> -->
          </div>
          <div class="fv-row my-5">
            <button
              type="button"
              class="btn w-100"
              :disabled="!toc"
              style="background: #323563;border-radius: 21px;color: #E9E9EF;"
              @click="submitCapture"
            >
              บันทึกและไปยังหน้าทำแบบทดสอบ
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import axios from "axios";
const video = ref();
const canvas = ref();
const captures: any = ref([]);
const isCameraOpen = ref(false);
const listBlogs = ref();
const listCourses = ref();
const listSyllabuses = ref();
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default defineComponent({
  name: "verify",
  components: {},
  data() {
    return {
      imageUrl: process.env.VUE_APP_API_URL,
      checkPermission: 0,
      preview: false,
      urlImage: "",
      toc: false,
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    
      // axios
      //   .get(
      //     process.env.VUE_APP_API_URL +
      //       "/api/v1/blogs" +
      //       "?limit=3&page=0&asc=true&sortBy=1"
      //   )
      //   .then(({ data }) => {
          // console.log(data);
      //     listBlogs.value = data.result;
      //   })
      //   .catch(({ error }) => {
          // console.log(error);
      //   });
    });
    return {
      video,
      canvas,
      captures,
      isCameraOpen,
      listBlogs,
      listCourses,
      listSyllabuses,
    };
  },
  mounted() {
    this.createCameraElement();
  },
  methods: {
    setAltImg(event) {
      event.target.src = "media/image-app/no-image.svg";
    },
    todo() {
      isCameraOpen.value = true;
      this.createCameraElement();
      // window.setInterval(() => {
      //   this.capture();
      // }, 2000); // interval set to one sec.
    },
    checkCamera() {
      this.checkPermission = window.setInterval(() => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices
            .getUserMedia({ video: true, audio: false })
            .then((stream) => {
              // console.log(stream);
            })
            .catch((error) => {
              clearInterval(this.checkPermission);
              // console.log(error);
              Swal.fire({
                title: "กรุณาให้สิทธิ webcam ในการทำข้อสอบ",
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#323563",
                cancelButtonColor: "#d33",
                confirmButtonText: "เข้าใจและเริ่มทำข้อสอบ",
                allowOutsideClick: false,
                // cancelButtonText: "ยกเลิก",
              }).then((result) => {
                if (result.isConfirmed) {
                  this.createCameraElement();
                }
              });
            });
        }
      }, 2000);
    },
    createCameraElement() {
      isCameraOpen.value = true;
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: false })
          .then((stream) => {
            video.value.srcObject = stream;
            video.value.play();
            this.checkCamera();
          })
          .catch((error) => {
            // console.log(error);
            Swal.fire({
              title: "กรุณาให้สิทธิ webcam ในการทำข้อสอบ",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#323563",
              cancelButtonColor: "#d33",
              confirmButtonText: "เข้าใจและเริ่มทำข้อสอบ",
              allowOutsideClick: false,
              // cancelButtonText: "ยกเลิก",
            }).then((result) => {
              if (result.isConfirmed) {
                this.createCameraElement();
              }
            });
          });
      }
    },
    stopCameraStream() {
      video.value = video.value.srcObject.getTracks();
      video.value.forEach((track) => {
        track.stop();
      });
    },
    capture() {
      /* eslint-disable */
      canvas.value = this.$refs.canvas;
      // console.log(canvas.value);
      var context: any = canvas.value
        .getContext("2d")
        .drawImage(this.video, 0, 0, 640, 480);
      // console.log(canvas.value.toDataURL("image/png"));
      const url = canvas.value.toDataURL("image/png");
      if (url) {
        this.urlImage = url;
        this.preview = true;
      }
      // fetch(url)
      //   .then((res) => res.blob())
      //   .then(async (blob) => {
      //     const file = new File([blob], "File name", { type: "image/png" });
      //     const formData = new FormData();
      //     formData.append("imageFace", file);
      //     formData.append("examRoomCode", this.$route.params.examRoomCode + "");
      //     await axios
      //       .post(
      //         process.env.VUE_APP_API_URL + "/enterExamRoomRegister",
      //         formData,
      //         {
      //           headers: {
      //             token: localStorage.getItem("id_token"),
      //           },
      //         }
      //       )
      //       .then((response) => {
              // console.log(response);
      //         if (response.data.code == 200) {
      //           this.$router.push({
      //             name: "exam-detail",
      //             params: { id: response.data.data.examRoomId },
      //           });
      //         } else {
      //           Swal.fire({
      //             text: response.data.message,
      //             icon: "error",
      //             buttonsStyling: false,
      //             confirmButtonText: "ลองใหม่ภายหลัง!",
      //             customClass: {
      //               confirmButton: "btn fw-semobold btn-light-danger",
      //             },
      //           });
      //         }
      //       })
      //       .catch((error) => {
      //         Swal.fire({
      //           text: error.message,
      //           icon: "error",
      //           buttonsStyling: false,
      //           confirmButtonText: "ลองใหม่ภายหลัง!",
      //           customClass: {
      //             confirmButton: "btn fw-semobold btn-light-danger",
      //           },
      //         });
      //       });
      //   });
    },
    submitCapture() {
      if (this.urlImage) {
        fetch(this.urlImage)
          .then((res) => res.blob())
          .then(async (blob) => {
            const file = new File([blob], "File name", { type: "image/png" });
            const formData = new FormData();
            formData.append("imageFace", file);
            formData.append(
              "examRoomCode",
              this.$route.params.examRoomCode + ""
            );
            await axios
              .post(
                process.env.VUE_APP_API_URL + "/enterExamRoomRegister",
                formData,
                {
                  headers: {
                    token: localStorage.getItem("id_token"),
                  },
                }
              )
              .then((response) => {
                // console.log(response);
                if (response.data.code == 200) {
                  this.$router.push({
                    name: "exam-detail",
                    params: { id: response.data.data.examRoomId },
                  });
                } else {
                  Swal.fire({
                    text: response.data.message,
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "ลองใหม่ภายหลัง!",
                    customClass: {
                      confirmButton: "btn fw-semobold btn-light-danger",
                    },
                  });
                }
              })
              .catch((error) => {
                Swal.fire({
                  text: error.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "ลองใหม่ภายหลัง!",
                  customClass: {
                    confirmButton: "btn fw-semobold btn-light-danger",
                  },
                });
              });
          });
      } else {
        Swal.fire({
          text: "ไม่พบข้อมูลรูปยืนยันตัวตน",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "ลองใหม่ภายหลัง!",
          customClass: {
            confirmButton: "btn fw-semobold btn-light-danger",
          },
        });
      }
    },
  },
  beforeUnmount() {
    clearInterval(this.checkPermission);
    let tracks = video.value.srcObject.getTracks();
    // console.log(tracks);
    tracks.forEach((track) => {
      track.stop();
    });
    // localStorage.setItem("reloaded", "1");
  },
});
</script>
<style></style>
